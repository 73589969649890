html {
    scroll-behavior: smooth;
    height: 100%;
    overflow: hidden;
}

body {
    background-color: #000;
    background-image: url('./img/00022-1246788941.jpeg');
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: 'Kalam', system-ui;
    box-sizing: border-box;
    color: #fff;
    min-height: 100vh;
}

div#root {
    width: 100%;
    height: 100%;
    position: fixed;
}

.scanlines {
    overflow: hidden;
    position: relative;
}
.scanlines:before,
.scanlines:after {
    display: block;
    pointer-events: none;
    content: '';
    position: absolute;
}
.scanlines:before {
    width: 100%;
    height: 2px;
    z-index: -1;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0.75;
    animation: scanline 6s linear infinite;
}
.scanlines:after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(
        to bottom,
        transparent 50%,
        rgba(0, 0, 0, 0.3) 51%
    );
    background-size: 100% 4px;
    animation: scanlines 12s steps(60) infinite;
}

/* ANIMATE UNIQUE SCANLINE */
@keyframes scanline {
    0% {
        transform: translate3d(0, 200000%, 0);
    }
}
@keyframes scanlines {
    0% {
        background-position: 0 50%;
    }
}

.fadeIn {
    animation: fade 1.5s ease-in;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
}

.App {
    text-align: center;
}

.content {
    max-width: 900px;
    height: 65vh;
    overflow-x: hidden;
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.fade-enter .content {
    opacity: 0;
    transform: translateX(-100%);
}
.fade-enter-active .content {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit .content {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit-active .content {
    opacity: 0;
    transform: translateX(100%);
}
.fade-enter-active .content,
.fade-exit-active .content {
    transition: opacity 500ms, transform 500ms;
}

.top-menu-enter {
    opacity: 0;
}
.top-menu-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}
.top-menu-exit {
    opacity: 1;
}
.top-menu-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}
.top-menu-exit-done {
    opacity: 0;
}

/**
 * Main wrapper
 */
.select-search-container {
    --select-search-background: rgba(0, 0, 0, 0.8);
    --select-search-border: #222;
    --select-search-selected: rgba(22, 45, 56, 0.8);
    --select-search-text: #fff;
    --select-search-subtle-text: #6c6f85;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #eff1f5;
    --select-search-font: 'Red Hat Display', sans-serif;

    width: 100%;
    position: relative;
    font-family: var(--select-search-font);
    font-size: 1rem;
    color: var(--select-search-text);
    box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
    .select-search-container {
        --select-search-font: 'Red Hat Display', sans-serif;
    }
}

@media (prefers-color-scheme: dark) {
    .select-search-container {
        --select-search-background: #000;
        --select-search-selected: #89b4fa;
        --select-search-text: #fff;
        --select-search-subtle-text: #a6adc8;
        --select-search-highlight: #1e1e2e;
    }
}

body.is-dark-mode .select-search-container {
    --select-search-background: #000;
    --select-search-selected: #89b4fa;
    --select-search-text: #fff;
    --select-search-subtle-text: #a6adc8;
    --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
    --select-search-background: #fff;
    --select-search-border: #dce0e8;
    --select-search-selected: #1e66f5;
    --select-search-text: #000;
    --select-search-subtle-text: #6c6f85;
    --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
    box-sizing: inherit;
}

.select-search-input {
    position: relative;
    z-index: 1;
    display: block;
    height: 48px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: var(--select-search-background);
    border: 2px solid var(--select-search-border);
    color: var(--select-search-text);
    border-radius: 3px;
    outline: none;
    font-family: var(--select-search-font);
    font-size: 16px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 48px;
    letter-spacing: 0.01rem;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
    margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
    border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search-input[readonly] {
    cursor: pointer;
}

.select-search-is-disabled .select-search-input {
    cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus
    .select-search-input,
.select-search-container:not(.select-search-is-disabled)
    .select-search-input:hover {
    border-color: var(--select-search-selected);
}

.select-search-select {
    background: var(--select-search-background);
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border: 2px solid var(--select-search-border);
    overflow: auto;
    max-height: 360px;
}

.select-search-select ul {
    padding: 0;
    margin: 0;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
    position: absolute;
    z-index: 2;
    top: 58px;
    right: 0;
    left: 0;
    border-radius: 3px;
    display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus
    .select-search-select {
    display: block;
}

.select-search-has-focus .select-search-select {
    border-color: var(--select-search-selected);
}

.select-search-options {
    list-style: none;
}

.select-search-option,
.select-search-not-found {
    display: block;
    height: 42px;
    width: 100%;
    padding: 0 16px;
    background: var(--select-search-background);
    border: none;
    outline: none;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.01rem;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
    background: var(--select-search-highlight);
}

.select-search-is-selected {
    font-weight: bold;
    color: var(--select-search-selected);
}

.select-search-group-header {
    font-size: 12px;
    text-transform: uppercase;
    background: var(--select-search-border);
    color: var(--select-search-subtle-text);
    letter-spacing: 0.1rem;
    padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
    margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
    margin-bottom: 10px;
}
